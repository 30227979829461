<template>
  <div>
    <div class="eSealBox">
      <img src="@/assets/image/productCenter/eSealBanner.png" alt="电子签章系统">
      <h2>电子签章系统</h2>
      <p>电子签章系统由支持PKI技术并支持安全算法的硬件和支持各种应用的软件组成，我们提供相应的软硬件一体化服务。电子签章系统将传统印章图片与可靠的电子签名技术完美结合，在电子文档中实现可视化电子签名的专用产品。该产品以电子化的签章代替传统的纸质签字盖章流程，帮助客户真正实现无纸化应用，可有效确认电子文档来源、确保文档的完整性、防止对文档未经授权的篡改、确保签名行为的不可否认。</p>
    </div>
    <v-functional :list="functional" />
    <v-featuresItem :list="features" />
    <v-learnMore />
    <v-networkSecurity />
  </div>
</template>

<script>
import learnMore from './components/learnMore.vue';
import featuresItem from './components/featuresItem.vue';
import functionalItem from './components/functionalItem2.vue';
import networkSecurity from '../../../components/networkSecurity.vue';
export default {
  data () {
    return {
      functional: [
        {
          icon: 'introduce1.png',
          title: '电子签章',
          intro:
            '可靠合规，具有法律效力的电子签章服务、批量电子签章服务、骑缝电子签章服务',
          image: 'functional2-img1.png'
        },
        {
          icon: 'eSeal1.png',
          title: '印章管理',
          intro: '印模和印章的制作、停用、启用、注销等',
          image: 'functional2-img2.png'
        },
        {
          icon: 'eSeal2.png',
          title: '证书管理',
          intro: 'Ukey证书生命周期管理、加密卡证书发放、加密卡证书自动续期',
          image: 'functional2-img3.png'
        },
        {
          icon: 'eSeal3.png',
          title: '模版管理',
          intro: '模板上传、模板编辑；利用模板进行签署',
          image: 'functional2-img4.png'
        },
        {
          icon: 'eSeal4.png',
          title: '云端监控',
          intro: '监控硬件的状态，包括密钥对、内存、在线管理等',
          image: 'functional2-img5.png'
        }
      ],
      features: [
        {
          title: '易用',
          intro:
            '人性化的设计，有大量可选配和扩充的应用功能，无障碍地轻松学习和使用。'
        },
        {
          title: '功能丰富',
          intro:
            '支持电子印章、手写签名、批量盖章、批量验证、文档锁定和强安全控制、打印份数控制、电子表单等功能。'
        }
      ]
    }
  },
  components: {
    'v-learnMore': learnMore,
    'v-featuresItem': featuresItem,
    'v-functional': functionalItem,
    'v-networkSecurity': networkSecurity,
  },
}
</script>

<style lang="scss" scoped>
.eSealBox {
  width: 7.5rem;
  height: 9.8rem;
  background: #f0f4ff;
  padding: 0.76rem 0.53rem 0;
  box-sizing: border-box;
  text-align: center;
  img {
    width: 5.01rem;
  }
  h2 {
    font-size: 0.48rem;
    color: #303b50;
    line-height: 0.67rem;
    margin: 0.23rem 0 0.26rem;
  }
  p {
    font-size: 0.28rem;
    color: #303b50;
    line-height: 0.44rem;
  }
}
</style>